import { Injectable, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { SubscriptionLike, Subject, Observable } from 'rxjs';


// declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  locationSubscription: SubscriptionLike;
  private subject = new Subject<any>();
  translation = require('../../assets/i18n/en.json');
  constructor(
    private spinner: NgxSpinnerService,
    private location: Location
  ) { }

  showLoading() {
    this.spinner.show();
  }

  hideLoading() {
    this.spinner.hide();
  }

  translate = (key: any) => {
    return this.translation[key];
  }


}
export class Pagination {
  currentPage: number;
  offset: number;
  perPage: number;
  totalPages: number;
  viewAll?: boolean;
  constructor() {
    this.currentPage = 1;
    this.perPage = 10;
    this.viewAll = false;
  }
}