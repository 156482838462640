import { HttpRequestsService } from '../http-requests.service';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';

import { environment } from 'src/environments/environment';
import { API_PATH, LOCAL_STORAGE_KEYS } from 'src/app/Constants/constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(private localstorage: LocalStorageService, private http: HttpRequestsService) {
    this.currentUserSubject = new BehaviorSubject<boolean>(!!this.localstorage.getLocalStore(LOCAL_STORAGE_KEYS.TOKEN));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public isAuthenticated(): boolean {
    return !!this.localstorage.getLocalStore(LOCAL_STORAGE_KEYS.TOKEN);
  }
  public isSuperAdmin() {
    return this.localstorage.getLocalStore(LOCAL_STORAGE_KEYS.IS_SUPERADMIN);
  }
  public get currentUserValue() {
    return this.currentUserSubject.asObservable();
  }
  login = async (data: any) => {
    return this.http.post(API_PATH.AUTHENTICATION.LOGIN, data, true);
  }
  sendOTP = async (data) => {
    return this.http.post(API_PATH.AUTHENTICATION.SEND_OTP, data, true);
  }
  verifyOTP = async (data) => {
    return this.http.post(API_PATH.AUTHENTICATION.VERIFY_OTP, data, true);
  }
  forgotPassword = async (data) => {
    return this.http.post(API_PATH.AUTHENTICATION.FORGOT_PASS, data, true);
  }
  resetPassword = async (data) => {
    return this.http.post(environment.API_URL + API_PATH.AUTHENTICATION.RESET_PASS, data, true);
  }
}
