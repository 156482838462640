import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.controls[key].markAsTouched();
      formGroup.controls[key].markAsDirty();
      if(formGroup.controls[key] && typeof formGroup.controls[key].value === 'string'){
        formGroup.controls[key].setValue(formGroup.controls[key].value.trim());
      }
      const mayBeFG = formGroup.controls[key] as FormGroup;
      if (mayBeFG.controls) {
        this.markFormGroupTouched(mayBeFG);
      }
    });
  }
}
