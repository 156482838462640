export const environment = {
  production: true,
  API_URL: 'https://dev.gymfans.com/api/',
  UPLOAD_URL: 'https://dev.gymfans.com/api/um/',
  AWS_SECRET_ACCESS_KEY: "rj5TkjpNM6a2VAEvIxPor43wsY/wYzvjHrfoSFAI",
  AWS_ACCESS_KEY_ID: "AKIAYGBPLXILTEIYPS7Q",
  AWS_REGION: "eu-west-1",
  AWS_BUCKET: "dev-media-original.gymfans.com",
  env: 'DEV',
};
