export class Constants {
  public static readonly TIME_STAMP_FORMAT = "YYYY-MM-DD HH:mm:ss";
  public static readonly POST_VIEW_FORMAT = "DD MMMM";
  static readonly APP_LOGO = '../../../assets/images/klervya-big.png';
  static readonly APP_MINI_LOGO = '../../../assets/images/klervya-small.png';
  static readonly PAGE_LIMIT = 10;
  static readonly STARTING_PAGE = 1;
  static readonly INITLOAD_COMMENT_LIMIT = 3;
  public static readonly TIME_FORMAT = "HH:mm:ss";
  public static readonly TIME_FORMAT_AM_PM = "hh:mm A";
  public static readonly TIME_FORMAT_HH_MM = "HH:mm";
  public static readonly DATE_FORMAT = "YYYY-MM-DD";
  public static readonly DATE_FORMAT_EVENT = "DD/MM/YYYY HH:mm:ss";
}

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'token',
  USERNAME: 'username',
  ID: 'id',
  IS_SUPERADMIN: 'isSuperAdmin'
};

export const UPLOAD_TYPE = {
  IMAGE: "image",
  IMAGE_JPEG: "image/jpeg",
  IMAGE_JPG: "image/jpg",
  VIDEO: "video/mp4",
  AUDIO: "audio",
  VIDEO_TYPE: "video"
};

export const REPORT_QUESTION = [

  "It's Spam",
  "I just don't like it",
  "Hate speech and or symbols",
  "False information",
  "Intellectual property violation",
  "Scam and fraud",
  "Suicide or self-injury",
  "Something else",
]
export const ROUTE_PATH = {
  ABSOLUTE: '',
  AUTH: 'auth',
  LOGIN: 'auth/login',
  ADMIN: 'admin',
  FAQ: 'faq',
  USERS: 'user',
  POSTS: 'posts',
  DASHBOARD: "dashboard",
  PROFILE: "profile",
  CHANGE_PASS: "changepw",
  CATEGORY: "category",
  NOTIFICATION: "notification",
  LIST: "list",
  DETAILS: "details/:id",
  ADD: "add",
  EDIT: "edit/:id",
  FOLLOWERSLIST: "followlist",
  FOLLOWINGLIST: "followinglist",
  SOCIAL: "social/:id",
  SUBSCRIBE: "sub/:id",
  NOTIFICATIONREQUEST: "add",
  CMS: 'cms',
  EVENT: 'event',
  PROMOTION: 'promos',
  TRANSACTION: 'transaction',
  SUPPORT: 'support',
  REPORT: 'report',
  SYSTEMCONFIG: 'sysconfig',
  PAYMENT: 'payment',
  COUNTRY: 'country',
  ADD_COUNTRY: 'country/add',
  EDIT_COUNTRY: 'country/edit/:id',
  Language: 'language',
  ADD_Language: 'language/add',
  EDIT_Language: 'language/edit/:id',
  STATE: 'state',
  ADD_STATE: 'state/add',
  EDIT_STATE: 'state/edit/:id',
  TICKET_CATEGORY: 'ticket-category',
  TICKET_CATEGORY_LIST: 'list',
  TICKET_CATEGORY_DETAIL: ':id',
  ADMIN_MANAGEMENT: 'management',
  ADMIN_MANAGEMENT_LIST: 'list',
  PROMOTIONAL_PARTNER: 'promotion',
  PROMOTIONAL_PARTNER_DETAILS: 'view/:id',
  ADD_ADVERTISEMENT: ":id/advertisement/add",
  EDIT_ADVERTISEMENT: ":id/advertisement/edit/:advertisementId",
  VIEW_ADVERTISEMENT: ":id/advertisement/:advertisementId",
  TICKET_MANAGEMANT: 'ticket-management',
  SPAM: 'spam',
  PROFILE_VERIFICATION: "profile-verification",
  EXERCISE_LIBRARY: "exercise-library"
};

export const API_PATH = {

  AUTHENTICATION: {
    LOGIN: 'am/admin/login',
    SEND_OTP: 'am/admin/send-otp',
    VERIFY_OTP: 'am/admin/verify-otp',
    FORGOT_PASS: 'am/admin/reset-password',
    CHANGE_PASS: 'am/admin/change-password',
    PROFILE: 'am/admin/profile',
    EDIT_PROFILE: 'am/admin/edit-profile',
    ADDCATEGORY: 'am/category/add-category',
    EDITCATEGORY: 'am/category/edit-category',
    GETCATEGORY: 'am/category/get-category?',
    GETCATEGORYBYID: 'am/category/get-category/',
    GETFAQ: 'am/faq/get-faq?',
    GETEVENT: 'am/event/list',
    GETNOTIFICATIONS: 'am/notifications/list',
    GETFAQBYID: 'am/faq/get-faq/',
    DELETECATEGORY: 'am/category/delete-category',
    DELETFAQ: 'am/faq/delete-faq',
    DELETEEVENT: 'am/event/delete',
    RESET_PASS: 'am/admin/reset-paasword',
    ADDFAQ: 'am/faq/add-faq',
    EDITFAQ: 'am/faq/edit-faq',
    GETUSER: 'am/user/list',
    GETUSERDETAIL: 'am/user/user-profile',
    GETFOLLOWERSLIST: 'am/user/follower-list',
    GETFOLLOWINGLIST: 'am/user/following-list',
    GETPOSTSLIST: 'am/user/user-posts',
    ADDCMS: 'am/cms/add',
    EDITCMS: 'am/cms/edit',
    GETSUBSCRIBEDCREATORLIST: 'am/user/subscribed-creators',
    GETUSERSUBSCRIPTIONLIST: 'am/user/user-subscriptions',
    ALLPOST: 'am/post/list',
    REPORT_ALLPOST: 'am/post/listReport',
    REPORT_ALLSHOTS: 'am/gymshot/listReport',
    POSTDETAIL: 'am/post/get',
    SHOTDETAIL: 'am/gymshot/get-gymshot',
    BLOCKPOST: 'am/post/block',
    DELETEPOST: 'am/post/delete',
    LOADCOMMENTS: 'am/post/comments',
    LOADSHOTCOMMENTS: 'am/gymshot/comments',
    GETCMSDETAILS: 'am/cms/get',
    UPLOADIMAGE: 'v1/upload/image-upload',
    ADDNOTIFICATION: 'nm/v1/notification/add-notification',
    PRMOTIONSLIST: 'am/promotion/list',
    TRANSACTIONLIST: 'am/transaction/list',
    PRMOTIONSDELETE: 'am/promotion/delete/',
    WITHDRAWSTATUS: 'nm/v1/admin/status-update',
    GETSUPPORT: 'am/user/support-user-list',
    SUPPORTSTATUS: 'am/user/support-status',
    UPDATESETTINGS: 'am/settings/edit',
    EXPORTUSERS: 'am/report/user/report',
    EXPORTTRANSACTION: 'am/report/transaction/report',
    EXPORTPOSTS: 'am/post/list',
    EXPORTPROMOTION: 'am/report/promotion/report',
    EXPORTCOMMISSION: 'am/report/commission/report',
    GETSETTINGS: 'am/settings',
    GETDASHBOARD: 'am/admin/dashboard',
    CATEGORYSTATUSUPDATE: 'am/category/status',
    REVENUEDATA: 'am/admin/revenue',
    DELETEUSER: 'am/user/delete-user',
    BLOCKUNBLOCKUSER: 'am/user/block-unblock',
    GET_TICKET_CATEGORY: 'am/ticketCategory/list-ticket-category',
    TICKET_CATEGORY_STATUS_UPDATE: 'am/ticketCategory/status',
    DELETE_TICKET_CATEGORY: 'am/ticketCategory/delete-ticket-category',
    ADD_TICKET_CATEGORY: 'am/ticketCategory/add-ticket-category',
    GET_SELECTED_TICKET_CATEGORY_DATA: 'am/ticketCategory/get-ticket-category',
    EDIT_TICKET_CATEGORY: 'am/ticketCategory/edit-ticket-category',
    ADD_TICKET_SUBCATEGORY: 'am/ticketCategory/add-sub-ticket-category',
    EDIT_TICKET_SUBCATEGORY: 'am/ticketCategory/edit-sub-ticket-category',
    GET_SELECTED_TICKET_SUBCATEGORY_DATA: 'am/ticketCategory/get-sub-ticket-category',
    DELETE_TICKET_SUBCATEGORY: 'am/ticketCategory/delete-sub-ticket-category',
    GET_ADMIN_LIST: 'am/subAdmin/list-sub-admin',
    ADD_ADMIN: 'am/subAdmin/register',
    GET_SELECTED_ADMIN_DATA: 'am/subAdmin/get-sub-admin',
    EDIT_ADMIN_DATA: 'am/subAdmin/edit-sub-admin-profile',
    DELETE_ADMIN: 'am/subAdmin/delete-sub-admin',
    GET_PROMOTIONAL_PARTNER_LIST: 'am/promotionalPartner/list-promotional-partner',
    REMOVE_PROMOTIONAL_PARTNER_LIST: 'am/promotionalPartner/delete-image-video',
    DELETE_PROMOTIONAL_PARTNER: 'am/promotionalPartner/delete-promotional-partner',
    GET_PROMOTIONAL_PARTNER_DATA: 'am/promotionalPartner/get-promotional-partner',
    EDIT_PROMOTIONAL_PARTNER_DATA: 'am/promotionalPartner/edit-promotional-partner',
    UPLOAD_COMPNY_LOGO: 'am/promotionalPartner/upload-image-promotional',
    ADD_PROMOTIONAL_PARTNER: 'am/promotionalPartner/add-promotional-partner',
    DELETE_ADVERTISEMENT: 'am/promotionalPartner/delete-advertisement',
    GET_ADVERTISEMENT_DETAIL: 'am/promotionalPartner/get-sub-advertisement',
    SAVE_ADVERTISEMENT_DETAIL: 'am/promotionalPartner/add-sub-advertisement',
    EDIT_ADVERTISEMENT_DETAIL: "am/promotionalPartner/edit-sub-advertisement",
    UPLOAD_ADVERTISEMENT_IMAGE: 'am/promotionalPartner/upload-image-advertisement',
    GET_TICKET_MANAGEMENT_LIST: 'am/ticketManagement/list-ticket-management',
    DELETE_TICKET_MANAGEMENT: 'am/ticketManagement/delete-ticket-management',
    GET_TICKET_MANAGEMENT_DETAIL: 'am/ticketManagement/get-ticket-management',
    EDIT_TICKET_MANAGEMENT: 'am/ticketManagement/edit-ticket-management',
    GET_USER_LIST: "am/user/list-user",
    GET_USER_DETAILS: "am/user/view",
    APPROVE_USER: "nm/v1/user/approve-profile",
    REJECT_USER: "nm/v1/user/reject-profile",
    REPORT_QUESTION: 'um/v1/gymshot/get-report-question-list',
    POST_APPROVE: 'am/post/approve',
    POST_REJECT: 'am/post/reject',
    SHOT_APPROVE: 'am/gymshot/approve',
    SHOT_REJECT: 'am/gymshot/reject',
    GET_EXERCISE: 'nm/v1/exercise/get-exercise-list-admin',
    GET_EXERCISE_BY_ID: 'nm/v1/exercise/get-exercise-by-admin',
    GET_PR_FOCUS_LIST: 'nm/v1/exercise/get-pr-focus-list',
    ADD_EXERCISE: 'nm/v1/exercise/add-exercise-by-admin',
    UPDATE_EXERCISE: 'nm/v1/exercise/update-exercise-admin',
    UPLOAD_EXE_IMAGE: 'nm/v1/exercise/upload-image-admin',
    UPLOAD_THUMB_IMAGE: 'nm/v1/exercise/upload-thumbnail-admin',
    DELETE_EXERCISE: 'nm/v1/exercise/delete-exercise-admin',
  },
  LANGUAGE: {
    LANGUAGE: 'am/language/',
    LIST: 'am/language/list',
    ADD_EDIT: 'am/language/add-edit',
    UPDATE_DETAILS: 'am/language/update',
  },
  COUNTRY: {
    LANGUAGE: 'am/country/',
    LIST: 'am/country/list',
    ADD_EDIT: 'am/country/add-edit',
    UPDATE_DETAILS: 'am/country/update',
  },
  STATE: {
    LANGUAGE: 'am/state/',
    LIST: 'am/state/list',
    ADD_EDIT: 'am/state/add-edit',
    UPDATE_DETAILS: 'am/state/update',
    COUNTRIES: 'um/v1/user/country',
  }
};
