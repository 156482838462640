import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '../Constants/constants';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root',
})
export class UtilityService {

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
  }

  clearStorage() {
    return localStorage.clear();
  }

  showLoading() {
    this.spinner.show();
  }

  hideLoading() {
    this.spinner.hide();
  }

  showSuccessToast(msg) {
    this.toastr.success(msg);
  }

  showErrorToast(msg) {
    this.toastr.error(msg);
  }

  showInfoToast(msg) {
    this.toastr.info(msg);
  }


  formatDate(dateString) {
    const date1 = moment(dateString, Constants.TIME_STAMP_FORMAT);
    const date2 = moment(moment(), Constants.TIME_STAMP_FORMAT);

    if (date2.diff(date1, 'days') > 8) {
      return moment(date1).format(Constants.POST_VIEW_FORMAT)
    } else if (date2.diff(date1, 'days') === 7) {
      return '1 week ago';
    } else if (date2.diff(date1, 'days') >= 2) {
      return `${date2.diff(date1, 'days')} days ago`;
    } else if (date2.diff(date1, 'days') >= 1) {
      return `1 day ago`;
    } else if (date2.diff(date1, 'hours') >= 2) {
      return `${date2.diff(date1, 'hours')} hours ago`;
    } else if (date2.diff(date1, 'hours') >= 1) {
      return `1 hour ago`;
    } else if (date2.diff(date1, 'minutes') >= 2) {
      return `${date2.diff(date1, 'minutes')} min ago`;
    } else if (date2.diff(date1, 'minutes') >= 1) {
      return `1 min ago`;
    } else if (date2.diff(date1, 'seconds') >= 3) {
      return `${date2.diff(date1, 'seconds')} sec ago`;
    } else {
      return 'now';
    }
  }
  downloadCsv(data, name) {
    const ws = XLSX.utils.json_to_sheet(data);
    const blobData = XLSX.utils.sheet_to_csv(ws, {
      FS: ',',
    });
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const extension = 'csv';
    const fileName = `${name}.${extension}`;
    this.downloadFile(url, fileName);
  }
  downloadFile(url, fileName) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
}
