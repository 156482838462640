import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gymfans-pwa-angular';
  public isLogin = false;
  constructor(
    public translate: TranslateService,
    public authService: AuthService
  ) {
    translate.setDefaultLang('en');
    this.authService.currentUserValue.subscribe(x => this.isLogin = x);
  }
}
